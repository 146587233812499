import React from "react"
import { Tooltip } from "antd";
import { PlaidLink } from 'react-plaid-link';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Loading } from '../../../../../common/components/Loading/Loading';
import Help from "../../../../../assets/images/dashboard/help-icon.svg";
import Lock from "../../../../../assets/images/dashboard/lock.svg";
import BankAccount from "./component/BankAccount";
import FindCardModal from "./component/FindCardModal";
import { ApiService, getAuthToken } from '../../../../../services/ApiService';
import './firstStep.scss';

class FirstStep extends React.Component {
    
    /**
     *
     *
     */
    constructor(props){
        super(props)
        this.state = {
            isMessageShow: false,
            modeForNoAuth: "manual",
        };
    }
    
    
    /**
     *
     *
     */
    handleClick = async (publicKey, metaData) => {
        const { onAccountsLoading, getAccountService, onSetDashboardStep } = this.props
        onAccountsLoading()
        const data = await ApiService.setPlaidAccount(publicKey, metaData);
        if (data && Array.isArray(data) && data.length && !data.some(x => x.subtype === "credit card" || x.subtype === "checking")) {
            this.props.setStateChange({
                isNoCreditCardPopUp: true,
                institutionName: data[0]?.institutionName
            });
        }
        getAccountService(true);
        onSetDashboardStep({isFirstStepComplete: false});
        localStorage.removeItem('LUCI_DATAS')
    }
    
    
    /**
     *
     *
     */
    handleExit = async (error, metaData) => {
        if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
            this.props.onCreateLinkToken()
        }
        if (error != null) {
            const body = { error, metaData };
            await ApiService.handlePlaidError(body);
        }
    }


    /**
     *
     *
     */
    getCardsEnable = () => {
        const { banks = [], isAccountsLoading } = this.props;
        let isEnable = false;
        banks.forEach(bank => {
            const length = (bank.accounts || []).filter(acc => acc.enabled).length;
            if (length && !isAccountsLoading) {
                isEnable = true
            }
        })
        return isEnable
    }


    /**
     *
     *
     */
    onMessageViewChange = () => {
        this.setState({
            isMessageShow: true
        })
        this.props.onFindCardModalChange()
        setTimeout(() => this.setState({isMessageShow: false}), 500)
    }


    /**
     *
     *
     */
    getAccountsLength = () => {
        const { banks = [], reLoginRequiredInstitutes } = this.props;
        let total = 0

        banks.forEach(bank => {
            const length = (bank.accounts || []).length;
            total += length
        })

        return (total || reLoginRequiredInstitutes.length)
    }


    /**
     *
     *
     */
    render() {
        
        // 
        const { 
            currentStep, 
            onShowBankDetails, 
            isAccountsLoading, 
            getAccountService, 
            linkToken, 
            onFindingCards
        } = this.props
        
        // 
        const { 
            isMessageShow, 
            modeForNoAuth, 
            token 
        } = this.state
        
        // 
        let object = {}
        if (isMessageShow) {
            object.visible = false
        }
        
        // 
        return (
            
            <div id="content" className="first-step">
            
                <div className="card luci-card unset-z-index">
                
                    {/* logged in? */}
                    {getAuthToken()? 
                        <>
                            {/*  */}
                            <div className="card-body z-index-11">
                                <div className="luci-title">
                                    Connect cards with luci to see results&nbsp;
                                    <Tooltip
                                        {...object}
                                        title={
                                            <div className="tooltip-luci">
                                                <i className="tasker-popover-arrow"/>
                                                luci uses the spending data from your selected cards to create a
                                                personalized recommendation. You can also manually enter your spending
                                                estimates by clicking
                                                <a href="#" className="link-text ml-1" onClick={this.onMessageViewChange}>here</a>.
                                            </div>
                                        }
                                        trigger={"click"}
                                        placement="bottom"
                                    >
                                        <img className="help-icon" src={Help}/>
                                    </Tooltip>
                                </div>
                                <div className="luci-text w-half">
                                    Securely share how you spend your money using Plaid.
                                    Plaid and luci use bank-level SSL encryption to keep your data secure.
                                    (<Link to="#" onClick={this.onMessageViewChange}>Click here</Link> to manually enter your spending estimates)
                                </div>
                            </div>
                            
                            {/*  */}
                            { !isAccountsLoading && currentStep === 0 && !!this.getAccountsLength() ?
                                <BankAccount
                                    getAccountService={getAccountService}
                                    onRefreshInstituteOnSuccess={this.handleClick}
                                    onRefreshInstituteOnExit={this.handleExit}
                                /> : null
                            }
                            
                            {/*  */}
                            {isAccountsLoading ?
                                <div className="luci-text w-half mb-4">
                                    <Loading isInteral={true} isCenter={true} />
                                </div> : null
                            }
                            
                            {/*  */}
                            <div className="card-body z-index-11 pt-0">
                                <div className="luci-text w-half">
                                    <div className="text-left r-btn view-sm">
                                        <PlaidLink
                                            token={linkToken || ""}
                                            onSuccess={this.handleClick}
                                            onExit={this.handleExit}
                                            className="plaid-btn"
                                        >
                                            <button
                                                className="btn luci-btn dark-btn font-weight-bold pt-3 pb-3 mr-3 mb-3"
                                            >
                                                <img className="lock-icon" src={Lock}/>
                                                Add Cards
                                            </button>
                                        </PlaidLink>
                                        {
                                            this.getAccountsLength() ?
                                                <button
                                                    className={`btn luci-btn ${(this.getCardsEnable()) && currentStep === 0 ? "primary-btn shadow-btn" : "gray-btn" } font-weight-bold pt-3 pb-3 mb-3`}
                                                    // className={`btn luci-btn primary-btn shadow-btn font-weight-bold pt-3 pb-3 mb-3`}
                                                    type="submit"
                                                    disabled={!this.getCardsEnable()}
                                                    onClick={onShowBankDetails}
                                                >
                                                    Find Your Perfect Card
                                                </button> : null
                                        }

                                    </div>
                                </div>
                            </div>
                        </>
                        
                        :
                        
                        
                        <>
                            {/* not logged in - ask manual or plaid */}
                            <div className="card-body z-index-11">
                            
                                <div className="luci-title">
                                    <p>
                                        <span className="line">Share with luci </span>
                                        &nbsp;
                                        <span className="line"> how you spend</span>
                                    </p>
                                </div>
                                <div>
                                
                                    {/* method buttons */}
                                    <div className="method-tab">
                                        <div
                                            className={`${modeForNoAuth === "manual" ? "selected" : ""} clickable-option`}
                                            onClick={() => this.setState({modeForNoAuth: "manual"})}
                                        >
                                            Manually
                                        </div>
                                        <div
                                            className={`${modeForNoAuth === "plaid" ? "selected" : ""} clickable-option`}
                                            onClick={() => this.setState({modeForNoAuth: "plaid"})}
                                        >
                                            Using Plaid
                                        </div>
                                        
                                        {/* tooltip */}
                                        <Tooltip
                                            {...object}
                                            title={
                                                <div className="tooltip-luci">
                                                    <i className="tasker-popover-arrow"/>
                                                    luci uses the spending data from your selected cards to create a
                                                    personalized recommendation. 
                                                </div>
                                            }
                                            trigger={"click"}
                                            placement="bottom"
                                        >
                                            <img className="help-icon" src={Help}/>
                                        </Tooltip>
                                    </div>
                                    
                                </div>
                                
                                {/* manually */}
                                {modeForNoAuth == "manual"?
                                    <FindCardModal
                                        onFindingCards={onFindingCards}
                                        isContentOnly={true}
                                    />
                                    :
                                    null
                                }
                                
                                {/* sign in link for plaid */}
                                {modeForNoAuth == "plaid"?
                                    <>
                                        <div className="luci-text w-half" style={{margin: "30px 0px", fontSize: 20}}>
                                            Securely share how you spend your money using Plaid.<br/>
                                            Plaid and luci use bank-level SSL encryption to keep your data secure.
                                        </div>
                                        
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <Link to="/signup">
                                                <button
                                                    className="btn luci-btn primary-btn font-weight-bold pt-3 pb-3 mr-3 mb-3"
                                                >
                                                    <img className="lock-icon" src={Lock}/>
                                                    Sign up to use this feature
                                                </button>
                                            </Link>
                                            <Link to="/signin">
                                                <button
                                                    className="btn luci-btn font-weight-bold pt-3 pb-3 mr-3 mb-3"
                                                >
                                                    Sign in
                                                </button>
                                            </Link>
                                        </div>
                                    </>
                                    : 
                                    null
                                }
                            </div>
                        </>
                    }
                    
                    

                </div>

            </div>
        )
    }
}


const mapStateToProps = state => ({
    banks: state.accounts.banks || [],
    reLoginRequiredInstitutes: state.accounts.reLoginRequiredInstitutes || [],
    linkToken: state.resultSetting.linkToken || ""
});

export default connect(mapStateToProps, null)(FirstStep);
